/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from 'react';
import { ModalProvider } from './src/context/modalContext';

import './src/fonts/fonts.css';
// import './src/pricing-calculator/assets/js/main.ts';

export const wrapRootElement = ({ element }) => {
  return <ModalProvider>{element}</ModalProvider>;
};
